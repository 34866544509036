
import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';
import BackOfficeStaffNewPageComponent from '../../../components/pages/dashboard/backofficestaff/BackOfficeStaffNewPageComponent'
// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class BackofficestaffNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/backoffice-staff/list/');
  }

  handle_new_backofficestaff = () => {
    this.props.history.push('/backoffice-staff/new');
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Back Office Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Back Office Staff Record"
          extra={[
            <Button key="3" onClick={this.handle_new_backofficestaff} type="primary">Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all}>Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <BackOfficeStaffNewPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default BackofficestaffNewPage;
