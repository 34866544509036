
export const BACKOFFICESTAFFS_START_NEW_STAFF_CREATION = 'BACKOFFICESTAFFSSTAFFS_START_NEW_STAFF_CREATION';
export const BACKOFFICESTAFFS_UPDATE_NEW_STAFF_CREATION = 'BACKOFFICESTAFFS_UPDATE_NEW_STAFF_CREATION';

export function startNewBackOfficeStaffCreation() {
  return {type: BACKOFFICESTAFFS_START_NEW_STAFF_CREATION};
}

export function updateNewBackOfficeStaffCreation(key, value) {
  // console.log(key, value);
  return {
    type: BACKOFFICESTAFFS_UPDATE_NEW_STAFF_CREATION,
    value: {
      key,
      value
    }
  };
}
