import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';

import JobListPageComponent from '../../../components/pages/dashboard/jobs/JobListPageComponent';

class JobListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/jobs/list/');
  }

  onClickNewJobs = () => {
    this.props.history.push('/jobs/new');
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Jobs Management'
        />
        <Card
          style={{ minHeight: 360 }}
          title='Current Job List'
          extra={[
            <Button key="3" onClick={this.onClickNewJobs}>Create New</Button>,
            <Button key="1" onClick={this.onClickShowAll} type="primary">Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <JobListPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default JobListPage;
