import Amplify, { Auth } from 'aws-amplify';
import flatten from 'flat';
import createHistory from 'history/createBrowserHistory';
import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { IntlProvider } from 'react-intl';
import { ThemeProvider } from 'react-jss';
import { connect } from 'react-redux';

import { Row } from 'antd';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import './App.css';
import AuthenticatedRoute from './components/routes/AuthenticatedRoute';
import UnauthenticatedRoute from './components/routes/UnauthenticatedRoute';
import  AppliedRoute from './components/routes/AppliedRoute';
import { signOut, validateToken } from './redux/actions/auth';
import { getAccounts } from './redux/actions/accounts';
import { authRoutes, unAuthRoutes, appliedRoutes } from './routes/index.jsx';
import messages_en from './translations/en.json';
import messages_zh from './translations/zh.json';
import { getCountries } from './redux/actions/countries';
import { getAdvertisers } from './redux/actions/advertisers';

const history = createHistory();
ReactGA.initialize('UA-179934135-3');
history.listen((location, action) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});


const messages = {
  'en': messages_en,
  'zh': messages_zh
};

const theme = {
  label_font_weight: 500,
  input_disabled_color: '#333 !important',
  input_disbaled_background_color: '#fafafa !important',
}

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: false,
      initialStateSample: '',
      current_user: null,
      loading: true
    };
  }

  async componentDidMount() {
    await this.props.validateTokenHandler();
    await this.props.getAccountsHandler();
    await this.props.getCountriesHandler();
    await this.props.getAdvertisersHandler();
    this.setState({loading: false})
  }

  userHasAuthenticated = async authenticated => {
    if (authenticated) {
      Auth.currentAuthenticatedUser({bypassCache: false})
        .then(user => {
          this.props.onLoginSuccess(user);
        })
        .catch(err => {
          console.log(err);
        });
    }

    this.setState({ isAuthenticated: authenticated });
  };

  handleLogout = () => {
    this.props.signOutHandler();
  };

  render() {
    //hardcode as true for template, change it when project starts
    const childProps = {
      isAuthenticated: this.props.auth.isAuthenticated,
      handleLogout: this.handleLogout,
    };

    return (
      <IntlProvider
        locale={this.props.language.language_current_ui}
        messages={flatten(messages[this.props.language.language_current_ui])}>
        <ThemeProvider theme={theme}>
          <Router history={history}>
            { !this.state.loading ?
              <Switch>
                {appliedRoutes.map((prop, key) => {
                  return <AppliedRoute path={prop.path} component={prop.component} key={`${key}`} props={childProps} />
                })}
                {unAuthRoutes.map((prop, key) => {
                  return <UnauthenticatedRoute path={prop.path} component={prop.component} key={`unauth_${key}`} props={childProps} />;
                })}
                {authRoutes.map((prop, key) => {
                  return <AuthenticatedRoute path={prop.path} component={prop.component} key={`auth_${key}`} props={childProps} />;
                })}
              </Switch> :
              <Row style={{ justifyContent: 'center'}}>
                <h1>Loading...</h1>
              </Row>
            }
          </Router>
        </ThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = ({ auth, language, }) => ({
  auth,
  language
});

// const mapDispatchToProps = ({
//   signOutHandler: signOut,
//   validateTokenHandler: validateToken,
//   getAccountsHandler: getAccounts,
//   getCountriesHandler: getCountries,
//   getJobClassificationsHandler: getJobClassifications,
//   getAdvertisersHandler: getAdvertisers,
//   getWorkplaceProfileListHandler: getList
// })

const mapDispatchToProps = ({
  signOutHandler: signOut,
  validateTokenHandler: validateToken,
  getAccountsHandler: getAccounts,
  getCountriesHandler: getCountries,
  getAdvertisersHandler: getAdvertisers,
})

const AppContainer = connect(mapStateToProps, mapDispatchToProps)(App);

export default AppContainer;
