
import React, { Component } from 'react';


import { Card, PageHeader, Button } from 'antd';
import AdvertiserViewPageComponentContainer from '../../../components/pages/dashboard/advertisers/AdvertiserViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class AdvertiserViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/advertisers/list/');
  }

  handle_new_advertisers = () => {
    this.props.history.push('/advertisers/new');
  }

  render() {

    console.log(this.props.match.params.client_id)

    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Advertiser Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Advertiser Details"
          extra={[
            <Button key="3" onClick={this.handle_new_advertisers}>Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all} type="primary">
                    Show All
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <AdvertiserViewPageComponentContainer
              client_id={this.props.match.params.client_id}
            />

          </div>
        </Card>
      </div>

    );
  }
}

export default AdvertiserViewPage;
