import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getBackOfficeStaffAPI = () => {
  const path = '/backoffice/v1/backOfficeStaff';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  getBackOfficeStaffAPI,
};