import React from 'react';
import {Switch, Redirect, NavLink} from 'react-router-dom';
import {Layout, Menu, Icon} from 'antd';

import dashboardRoutes from '../routes/dashboard.jsx';
import AuthenticatedRoute from '../components/routes/AuthenticatedRoute';
import DashboardHeaderContainer from '../components/layout/dashboard/DashboardHeader.jsx';

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

class Dashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mobileOpen: false,
      miniActive: false,
      isAuthenticated: false
    };
    // this.resizeFunction = this.resizeFunction.bind(this);
  }
  // componentDidMount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(this.refs.mainPanel, {
  //       suppressScrollX: true,
  //       suppressScrollY: false
  //     });
  //     document.body.style.overflow = "hidden";
  //   }
  //   window.addEventListener("resize", this.resizeFunction);
  // }
  // componentWillUnmount() {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps.destroy();
  //   }
  //   window.removeEventListener("resize", this.resizeFunction);
  // }
  // componentDidUpdate(e) {
  //   if (e.history.location.pathname !== e.location.pathname) {
  //     this.refs.mainPanel.scrollTop = 0;
  //     if (this.state.mobileOpen) {
  //       this.setState({ mobileOpen: false });
  //     }
  //   }
  // }
  // handleDrawerToggle = () => {
  //   this.setState({ mobileOpen: !this.state.mobileOpen });
  // };
  // getRoute() {
  //   return this.props.location.pathname !== "/maps/full-screen-maps";
  // }
  // sidebarMinimize() {
  //   this.setState({ miniActive: !this.state.miniActive });
  // }
  // resizeFunction() {
  //   if (window.innerWidth >= 960) {
  //     this.setState({ mobileOpen: false });
  //   }
  // }

  onCollapse = collapsed => {
    console.log(collapsed);
    this.setState({collapsed});
  };

  onClick = () => {
    window.location.assign('/datePickerSample');
  };

  switchRoutes() {
    return (
      <Switch>
        {dashboardRoutes.map((prop, key) => {
          if (prop.redirect) {
            return <Redirect from={prop.path} to={prop.pathTo} key={key} />;
          }
          if (prop.collapse) {
            return prop.views.map((prop, key) => {
              return (
                <AuthenticatedRoute
                  path={prop.path}
                  component={prop.component}
                  key={key}
                  props={{
                    isAuthenticated: this.props.isAuthenticated,
                    current_user: this.props.current_user,
                    reload_user: this.props.reload_user
                  }}
                />
              );
            });
          }
          return (
            <AuthenticatedRoute
              path={prop.path}
              component={prop.component}
              key={key}
              props={{
                isAuthenticated: this.props.isAuthenticated,
                current_user: this.props.current_user,
                reload_user: this.props.reload_user
              }}
            />
          );
        })}
      </Switch>
    );
  }

  generate_sidebar = () => {
    return (
      <Menu theme='dark' defaultSelectedKeys={['1']} mode='inline' style={{height: '100%'}}>
        <Menu.Item key='1'>
          <NavLink to='/'>
            <Icon type='user' />
            <span>Dashboard</span>
          </NavLink>
        </Menu.Item>
        {/* Create sidebar and menu pages */}
        <SubMenu
          key='account_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Accounts</span>
            </span>
          }
        >
          <Menu.Item key='account_list'>
            <NavLink to='/account/list'>Account List</NavLink>
          </Menu.Item>
          <Menu.Item key='account_new'>
            <NavLink to='/account/new'>New Account Member</NavLink>
          </Menu.Item>
        </SubMenu>
        {/*<SubMenu
          key='backofficestaff_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Back Office Staff</span>
            </span>
          }
        >
          <Menu.Item key='2'>
            <NavLink to='/backoffice-staff/new'>New Back Office Staff Member</NavLink>
          </Menu.Item>
          <Menu.Item key='3'>
            <NavLink to='/backoffice-staff/list'>Back Office Staff List</NavLink>
          </Menu.Item>
        </SubMenu>*/}
        <SubMenu
          key='admin_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Admins</span>
            </span>
          }
        >
          <Menu.Item key='admin_list'>
            <NavLink to='/admin/list'>Admin List</NavLink>
          </Menu.Item>
          <Menu.Item key='admin_new'>
            <NavLink to='/admin/new'>New Admin Member</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key='advertisers_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Advertisers</span>
            </span>
          }
        >
          <Menu.Item key='advertisers_list'>
            <NavLink to='/advertisers/list'>Advertiser List</NavLink>
          </Menu.Item>
          <Menu.Item key='advertisers_new'>
            <NavLink to='/advertisers/new'>New Advertiser</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key='jobs_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Jobs</span>
            </span>
          }
        >
          <Menu.Item key='jobs_list'>
            <NavLink to='/jobs/list'>Job List</NavLink>
          </Menu.Item>
          <Menu.Item key='jobs_new'>
            <NavLink to='/jobs/new'>New Job</NavLink>
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key='applicants_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Applicants</span>
            </span>
          }
        >
          <Menu.Item key='applicants_list'>
            <NavLink to='/applicants/list'>Applicant List</NavLink>
          </Menu.Item>
          <Menu.Item key='applicants_new'>
            <NavLink to='/applicants/new'>New Applicant</NavLink>
          </Menu.Item>
        </SubMenu>
        <SubMenu
          key='subscriptions_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Subscriptions</span>
            </span>
          }
        >
          <Menu.Item key='subscriptions_list'>
            <NavLink to='/subscriptions/list'>Subscription List</NavLink>
          </Menu.Item>
          <Menu.Item key='subscriptions_new'>
            <NavLink to='/subscriptions/new'>New Subscription</NavLink>
          </Menu.Item>
        </SubMenu>
        <Menu.Item key='legal'>
          <NavLink to='/legal'>
            <Icon type='user' />
            <span>Legal</span>
          </NavLink>
        </Menu.Item>
        <SubMenu
          key='settings_sub'
          title={
            <span>
              <Icon type='team' />
              <span>Settings</span>
            </span>
          }
        >
          <Menu.Item key='user_profile_sub'>
            <NavLink to='/user-profile'>
              <Icon type='user' />
              <span>User Profile</span>
            </NavLink>
          </Menu.Item>
        </SubMenu>
      </Menu>
    );
  };

  render() {
    const {classes, ...rest} = this.props;
    // console.log(this.props);
    // const mainPanel =
    //   classes.mainPanel +
    //   " " +
    //   cx({
    //     [classes.mainPanelSidebarMini]: this.state.miniActive,
    //     [classes.mainPanelWithPerfectScrollbar]:
    //       navigator.platform.indexOf("Win") > -1
    //   });
    //   // console.log(this.props);
    return (
      <Layout style={{minHeight: '100vh'}}>
        <Sider style={{minHeight: '100vh'}} collapsible collapsed={this.state.collapsed} onCollapse={this.onCollapse}>
          <div className='logo' />
          <img
            src={require('../assets/img/brand/PlatinumJobSeeker_300px.png')}
            alt=''
            target='_blank'
            style={{
              width: '100%',
              paddingTop: 16,
              marginBottom: 16,
              maxHeight: 64,
              maxWidth: 200
            }}
          />
          {this.generate_sidebar()}
        </Sider>
        <Layout>
          <Header
            style={{
              background: '#fff',
              padding: 0
            }}
          >
            <DashboardHeaderContainer
              isAuthenticated={this.props.isAuthenticated}
              userHasAuthenticated={this.props.userHasAuthenticated}
              handleLogout={this.props.handleLogout}
            />
          </Header>
           <Layout>
            <Content style={{margin: '16px'}}>
              <div
                style={{
                  padding: 24, 
                   background: '#fff',
                  minHeight: 360 
                }}
              >
                <div>{this.switchRoutes()}</div>
              </div>
            </Content>
          </Layout>
          <Footer style={{textAlign: 'left'}}>
            <img src={require('../assets/img/brand/TickWithBorder_20px.png')} />
            <strong>
              Platinumjobseeker<sup>TM</sup>
            </strong>{' '}
            BackOffice Portal © 2020-2021
            <br />
            <small>
              Created by{' '}
              <a href='https://deliostech.com/' target='_blank'>
                Delios Technology Inc
              </a>
            </small>
          </Footer>
        </Layout>
      </Layout>

      // <div className={classes.wrapper} style={{backgroundImage:`url(${background_image})`}}>
      //   <SidebarContainer
      //     routes={dashboardRoutes}
      //     logoText={"Forex Worldwide"}
      //     logo={logo}
      //     image={image}
      //     handleDrawerToggle={this.handleDrawerToggle}
      //     open={this.state.mobileOpen}
      //     color="blue"
      //     bgColor="black"
      //     miniActive={this.state.miniActive}
      //     {...rest}
      //   />
      //   <div className={mainPanel} ref="mainPanel">
      //     <HeaderContainer
      //       sidebarMinimize={this.sidebarMinimize.bind(this)}
      //       miniActive={this.state.miniActive}
      //       routes={dashboardRoutes}
      //       handleDrawerToggle={this.handleDrawerToggle}
      //       {...rest}
      //     />
      //     {/* On the /maps/full-screen-maps route we want the map to be on full screen - this is not possible if the content and conatiner classes are present because they have some paddings which would make the map smaller */}
      //     {this.getRoute() ? (
      //       <div className={classes.content}>
      //         <div className={classes.container}>{this.switchRoutes()}</div>
      //       </div>
      //     ) : (
      //       <div className={classes.map}>{this.switchRoutes()}</div>
      //     )}
      //     {this.getRoute() ? <Footer fluid /> : null}
      //   </div>
      // </div>
    );
  }
}

// Dashboard.propTypes = {
//   classes: PropTypes.object.isRequired
// };

export default Dashboard;
