import React, { Component } from 'react';

import { Card, PageHeader } from 'antd';
import UserProfilePageComponent from '../../components/pages/dashboard/user_profile/UserProfilePageComponent';

class UserProfilePage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="User Profile"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your User Details"
        >
          <div style={{ paddingTop: 24 }}>
            <UserProfilePageComponent />
          </div>
        </Card>

      </div>

    );
  }
}

export default UserProfilePage;
