import React from 'react';
import { withRouter } from "react-router-dom";
import { PageHeader } from 'antd';
import { FormattedMessage } from 'react-intl';

import LoginPageComponent from './LoginPageComponent';

function LoginPage(props) {
  return (
    <div style={{ padding: 24, background: '#fff', minHeight: 360 }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title={<FormattedMessage id="login_page.main_title" />}
      />
      <LoginPageComponent
        isAuthenticated={props.isAuthenticated}
        userHasAuthenticated={props.userHasAuthenticated}
      />
    </div>
  )
};

export default withRouter(LoginPage);
