import React, {Component} from 'react';
import {Card, PageHeader, Button} from 'antd';

import SubscriptionListPageComponent from '../../../components/pages/dashboard/subscriptions/SubscriptionListPageComponent';

class SubscriptionListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/subscriptions/list/');
  };

  onClickNewSubscription = () => {
    this.props.history.push('/subscriptions/new');
  };

  render() {
    return (
      <div
        style={{
          padding: 24,
          background: '#fff',
          minHeight: 360
        }}
      >
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title='Subscription Management'
        />
        <Card
          style={{minHeight: 360}}
          title='Current Subscription List'
          extra={[
            <Button key='3' onClick={this.onClickNewSubscription}>
              Create New
            </Button>,
            <Button key='1' onClick={this.onClickShowAll} type='primary'>
              Show All
            </Button>
          ]}
        >
          <div style={{paddingTop: 24}}>
            <SubscriptionListPageComponent />
          </div>
        </Card>
      </div>
    );
  }
}

export default SubscriptionListPage;
