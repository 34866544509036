
import React, { Component, useEffect, useState } from 'react';
import { Table } from 'antd';
import {connect} from 'react-redux';
import { NavLink } from "react-router-dom";
import { Input, Button } from 'antd';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
function AccountListPageComponent(props) {
  const { accounts } = props;
  const [dataSource, setDataSource] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState(0);

  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ?
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) : "",
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
          text
        ),
  });

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "descend",
      render: text => <a>{text.toString().padStart(5, '0')}</a>,
    },
    {
      title: 'Name',
      dataIndex: 'file_by',
      ...getColumnSearchProps('file_by'),
    },
    {
      title: 'User Admin ID',
      dataIndex: 'admin_backofficestaff_id',
      ...getColumnSearchProps('admin_backofficestaff_id'),
    },
    {
      title: 'Actions',
      key: 'action',
      render: (text, record) => (
        <span>
          <NavLink to={`/account/view/${record.id}`} >View</NavLink>
        </span>
      ),
    },
  ];

  useEffect(() => {
    if (accounts) {
      let mDataSource = [];
      for (let i = 0; i < accounts.length; i++) {
        mDataSource.push({
          key: accounts[i].id,
          id: accounts[i].id,
          file_by: accounts[i].file_by,
          admin_backofficestaff_id: accounts[i].admin_backofficestaff_id
        });
      }
      setDataSource(mDataSource);
    }
  }, [accounts]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchedColumn(dataIndex);
    setSearchText(selectedKeys[0]);
  };

  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };

  return (
    <React.Fragment>
      <Table
        columns={columns}
        dataSource={dataSource}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => ({
  accounts: state.accounts.accounts
});

const mapDispatchToProps = ({})

export default connect(mapStateToProps, mapDispatchToProps)(AccountListPageComponent);
