import { API } from 'aws-amplify';

// export const UPDATE_CURRENT_BRAND = "UPDATE_CURRENT_BRAND";
export const UPDATE_CURRENT_USER = 'UPDATE_CURRENT_USER';
export const DATA_COUNTRIES = 'DATA_COUNTRIES';
export const DATA_ACCOUNTS = 'DATA_ACCOUNTS';
// export const UPDATE_CURRENT_USER_ID = "UPDATE_CURRENT_USER_ID";
// export const UPDATE_CURRENT_USER_LOG_OUT = "UPDATE_CURRENT_USER_LOG_OUT";
// export const UPDATE_ECONOMIC_CALENDAR = "UPDATE_ECONOMIC_CALENDAR";

export function onLoginSuccess(user) {
  return async function(dispatch) {
    try {
      // await update_client_database(aws_cognito_id, {
      //   portal_last_login_datetime: new Date().toISOString().slice(0, 19).replace('T', ' ')
      // })
      // console.log(user);
      // const backofficestaff_record = await get_current_backofficestaff_details(user)
      const {
        backofficestaff_record, user_record, backofficestaff_access
      } = await get_current_backofficestaff_details(user)
      // const staff_record = await get_current_staff_details(user)
      const countries_list = await get_countries();
      const accounts_list = await get_accounts();
      // const division_details = await get_current_division_details(client_record.division_id)
      // console.log(staff_record)
      // console.log(division_details)
      if (backofficestaff_record) {
        dispatch(updateCurrentStaffIntoStore(backofficestaff_record, user_record, backofficestaff_access));
      }
      dispatch(updateCountriesIntoStore(countries_list));
      dispatch(updateAccountsIntoStore(accounts_list));
      // dispatch(createNewStaffMemberComplete());
    } catch (e) {
      // alert(e.message);
      console.log(e);
    }
  };
}

async function get_current_backofficestaff_details(user) {
  // console.log(user);
  if (user.username) {
    return API.get(
      'user-backofficestaff',
      `/get/aws-cognito/${user.username}`
    )
      .then(response => {
        // console.log(response.payload);
        return {
          backofficestaff_record: response.payload.backofficestaff,
          user_record: response.payload.user_backofficestaff,
          backofficestaff_access: response.payload.backofficestaff_access
        };
      })
      .catch(error => {
        console.log(error);
      })
  } else if (user.email) {
    return API.get(
      'user-backofficestaff',
      `/get/email/${user.email}`
    )
      .then(response => {
        // console.log(response.payload);
        return response.payload;
      })
      .catch(error => {
        console.log(error);
      })
  }
}

async function get_countries() {
  // console.log(user);
  return API.get(
    'countries',
    '/list'
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

async function get_accounts() {
  // console.log(user);
  return API.get(
    'accounts',
    '/list'
  )
    .then(response => {
      // console.log(response.payload);
      return response.payload;
    })
    .catch(error => {
      console.log(error);
    })
}

export function updateCountriesIntoStore(countries) {
  return {
    type: DATA_COUNTRIES,
    countries: countries,
  };
}

export function updateAccountsIntoStore(accounts) {
  return {
    type: DATA_ACCOUNTS,
    accounts: accounts,
  };
}

export function updateCurrentStaffIntoStore(backofficestaff_record, user_record, backofficestaff_access) {
  return {
    type: UPDATE_CURRENT_USER,
    current_user_id: user_record.id,
    current_user_record: user_record,
    current_backofficestaff_id: backofficestaff_record.id,
    current_backofficestaff_record: backofficestaff_record,
    backofficestaff_access: backofficestaff_access,
    // brand: brand,
    // current_division: division_details
  };
}
