
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Table, Input, Button } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
class AdvertiserListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { dataSource: [] }
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      {
        title: 'Name',
        dataIndex: 'file_by',
        ...this.getColumnSearchProps('file_by'),
      },
      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/advertisers/view/${record.id}`} >View</NavLink>
          </span>
        ),
      },
    ];

  }

  componentDidMount() {
    const dataSource = [];
    const { advertisersList } = this.props;
    for (let i = 0; i < advertisersList.length; i++) {
      dataSource.push({
        key: advertisersList[i].id,
        id: advertisersList[i].id,
        email: advertisersList[i].email,
        first_name: advertisersList[i].first_name,
        last_name: advertisersList[i].last_name,
        file_by: advertisersList[i].file_by
      });
    }
    this.setState({ dataSource: dataSource })
  }

  // TABLE SEARCH
  // ========================================================================

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ?
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
          text
        ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  // ========================================================================

  render() {
    return (
      <React.Fragment>
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    advertisersList: state.advertisers.advertisers, // Advertisers Action Store
  };
};
const mapDispatchToProps = ({
})

export default connect(mapStateToProps, mapDispatchToProps)(AdvertiserListPageComponent);
