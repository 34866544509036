
import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';
import AccountListPageComponent from '../../../components/pages/dashboard/accounts/AccountListPageComponent';
class AccountListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/account/list/');
  }

  handle_new_accounts = () => {
    this.props.history.push('/account/new');
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Account Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Current Account List"
          extra={[
            <Button key="3" onClick={this.handle_new_accounts}>Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all} type="primary">Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <AccountListPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default AccountListPage;
