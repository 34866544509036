import React, {Component} from 'react';
import withStyles from 'react-jss'
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

// import {
//   startNewBackOfficeStaffCreation,
// } from "../../../../redux/actions/backofficestaffs";

// import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
// import { Steps } from 'antd';
// import { message } from 'antd';
// import { Card, Button } from 'antd';
// import { notification } from 'antd';

import { Tabs } from 'antd';

import StaffSummary from './backofficestaff_view/StaffSummary';
// import StaffContact from './backofficestaff_view/StaffContact';
// import StaffAddress from './backofficestaff_view/StaffAddress';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};
const { TabPane } = Tabs;
function callback(key) {
  //console.log(key);
}

class BackOfficeStaffViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {current_step: 0,}
  }

  componentDidMount() {
    
  }

  render() {
    const { classes } = this.props;
    const { current_step } = this.state;

    // console.log(this.props);
    // if (!this.props.backofficestaffs && !this.props.backofficestaffs.new_backoffice_staff_data) {
    //   return null;
    // }

    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Staff Summary" key="1">

            <StaffSummary
              staff_id={this.props.staff_id}
            />

          </TabPane>
          {/*<TabPane tab="Contact" key="2">
            <StaffContact
              staff_id={this.props.staff_id}
            />
          </TabPane>
          <TabPane tab="Address" key="3">
            <StaffAddress
              staff_id={this.props.staff_id}
            />
          </TabPane>*/}
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    backofficestaffs: state.backofficestaffs,
    // staffs: state.staffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const BackOfficeStaffViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(BackOfficeStaffViewPage)));

export default BackOfficeStaffViewPageComponentContainer;
