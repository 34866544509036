import {
  getAdminsAPI,
} from '../../services/admins';
import { adminsActionType } from '../type';

export const ADMINS_START_NEW_ADMIN_CREATION = 'ADMINS_START_NEW_ADMIN_CREATION';
export const ADMINS_UPDATE_NEW_ADMIN_CREATION = 'ADMINS_UPDATE_NEW_ADMIN_CREATION';

const startNewStaffCreation = () => {
  return {type: ADMINS_START_NEW_ADMIN_CREATION};
}

const updateNewStaffCreation = (key, value) => {
  // console.log(key, value);
  return {
    type: ADMINS_UPDATE_NEW_ADMIN_CREATION,
    value: {
      key,
      value
    }
  };
}

const getAdmins = () => {
	return (dispatch) => {
		return getAdminsAPI()
			.then((data) => {
				if (data) {
					return dispatch({
						type: adminsActionType.GET_ADMINS,
						admins: data.data
					});
				} else {}
			})
			.catch(error => {
				console.log(error);
			})
	}
}

export {
  startNewStaffCreation,
  updateNewStaffCreation,
  getAdmins,
};