import {
  // UPDATE_CURRENT_BRAND,
  UPDATE_CURRENT_USER,
  DATA_COUNTRIES,
  DATA_ACCOUNTS,
  // UPDATE_CURRENT_USER_ID,
  // UPDATE_CURRENT_USER_LOG_OUT, UPDATE_ECONOMIC_CALENDAR
} from '../actions/app_state';

const initialState = {
  // current_brand: null,
  current_user_id: null,
  current_user_record: {},
  current_backofficestaff_id: null,
  current_backofficestaff_record: {},
  backofficestaff_access: [],
  accounts: [],
  // current_client_id: null,
  // current_client_aws_cognito_id: null,
  // show_economic_calendar: true
};

function app_state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_CURRENT_USER:
      return {
        ...state,
        current_user_id: action.current_user_id,
        current_user_record: action.current_user_record,
        current_backofficestaff_id: action.current_backofficestaff_id,
        current_backofficestaff_record: action.current_backofficestaff_record,
        backofficestaff_access: action.backofficestaff_access,
      };

    case DATA_COUNTRIES:
      return {
        ...state,
        data_countries_list: action.countries,
      };

    case DATA_ACCOUNTS:
      return {
        ...state,
        accounts: action.accounts,
      };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default app_state;
