import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { Tabs } from 'antd';

import AccountSummary from './account_view/AccountSummary';
import AccountBranding from './account_view/AccountBranding';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}

class AccountViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  componentDidMount() {
    
  }
  render() {
    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Summary" key="1">
            <AccountSummary
              account_id={this.props.account_id}
            />
          </TabPane>
          <TabPane tab="Branding" key="2">
            <AccountBranding
              account_id={this.props.account_id}
            />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    accounts: state.accounts,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const AccountViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AccountViewPageComponent)));

export default AccountViewPageComponentContainer;
