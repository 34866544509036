import { accountType } from '../type';
import { getListAPI, getAccountAPI, createAccountAPI } from '../../services/accounts';

/* Define New action  */
const getAccounts = () => {
	return (dispatch) => {
		return getListAPI()
			.then((data) => {
				if (data) {
					return dispatch({
						type: accountType.GET_ACCOUNTS,
						accounts: data.data
					});
				} else { }
			})
			.catch(error => {
				// TODO
			})
	}
};

const createAccount = (param) => {
  return (dispatch) => {
    return createAccountAPI(param)
      .then((data) => {
        if (data) {
          return data.data
        } throw {}
      })
      .catch(error => {
      })
  }
}

const getAccount = (id) => {
  return getAccountAPI(id)
    .then((data) => {
      if (data) {
        return data.data
      } else throw new Error(data.msg);
    })
    .catch(error => {
      throw new Error(error)
    })
}

export {
	getAccounts,
	getAccount,
	createAccount
}
