import React from 'react';
import { Card, PageHeader } from 'antd';
import { Collapse } from 'antd';

const { Panel } = Collapse;

const LegalPage = () => {
  return (
    <div
      style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}
    >
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title='LegalPage'
      />
      <Card style={{ minHeight: 360 }} title='LegalPage'>
        <div style={{ paddingTop: 24 }}>
        </div>
      </Card>
    </div>
  );
}

export default LegalPage;
