import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';

import AdminViewPageComponentContainer from '../../../components/pages/dashboard/admin/AdminViewPageComponent';

class AdminViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/admin/list/');
  }

  onClickNewAdmin = () => {
    this.props.history.push('/admin/new');
  }

  render() {

    //console.log(this.props.match.params.staff_id);

    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Admin Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Admin Details"
          extra={[
            <Button key="3" onClick={this.onClickNewAdmin}>Create New</Button>,
            <Button key="1" onClick={this.onClickShowAll}>
              Show All
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <AdminViewPageComponentContainer
              staff_id={this.props.match.params.admin_id}
            />

          </div>
        </Card>
      </div>

    );
  }
}

export default AdminViewPage;