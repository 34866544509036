import React, { useEffect, useState } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom';

import {
  startNewStaffCreation,
  updateNewStaffCreation
} from '../../../../redux/actions/admins';

import { API } from 'aws-amplify';
import { Steps, Button, notification } from 'antd';

import NewStaffWizard01Container from './new_admin_wizard/NewStaffWizard01';
import NewStaffWizard02Container from './new_admin_wizard/NewStaffWizard02';
import NewStaffWizard03Container from './new_admin_wizard/NewStaffWizard03';

import { create_staff_cognito_account } from '../../../../library/staff';
import { checkAdminEmailAPI } from '../../../../services/admins';
const generator = require('generate-password');

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

const steps = [
  {
    title: 'Admin Details',
    content: <NewStaffWizard01Container />,
  },
  {
    title: 'Contact Details',
    content: <NewStaffWizard02Container />,
  },
  {
    title: 'Confirm',
    content: <NewStaffWizard03Container />,
  },
];

function AdminNewPageComponent (props) {
  const { startNewStaffCreation, updateNewStaffCreation, admins, classes } = props;
  const [currentStep, setCurrentStep] = useState(0);
  const [stepStatusError, setStepStatusError] = useState(false);
  const history = useHistory();
  useEffect(()=> {
    startNewStaffCreation();
  }, []);

  const validateStep1 = async () => {
    let validate = true;
    updateNewStaffCreation('email_error_message', ' ');
    updateNewStaffCreation('account_error_message', ' ');

    if (!(admins.new_admin_data.email.length > 2)) {
      updateNewStaffCreation('email_status', 'error');
      updateNewStaffCreation('email_error_message', 'Email is required');
      validate = false;
    } else {
      validate = await checkAdminEmailAPI(admins.new_admin_data.email)
        .then(
          database_result => {
            console.log(database_result);
            if (!database_result.data) {
              updateNewStaffCreation('email_status', 'error');
              updateNewStaffCreation('email_error_message', 'Email is already in the database');
            }

            return database_result.data;
          }
        )
    }

    if (!(admins.new_admin_data.first_name.length > 2)) {
      await updateNewStaffCreation('first_name_status', 'error');
      validate = false;
    }
    if (!(admins.new_admin_data.last_name.length > 2)) {
      await updateNewStaffCreation('last_name_status', 'error');
      validate = false;
    }
    //#12 adjust staff to handle accounts
    if (!admins.new_admin_data.account_id) {
      await updateNewStaffCreation('account_id_status', 'error');
      await updateNewStaffCreation('account_error_message', 'Account is required');
      validate = false;
    }

    console.log(validate);
    return validate;
  }

  const nextStep  = async () => {
    let validate = true;
    switch (currentStep) {
      case 0:
        validate = await validateStep1();
        break;
      default:
    }

    if (!validate) {
      setStepStatusError(true);
      return;
    }
    setCurrentStep(currentStep + 1);
  }

  const prevStep = () => {
    setCurrentStep(currentStep - 1);
  }

  const handleSubmit = async (event) => {
    openNotificationWithIcon('info', 'In Process', 'Creating new staff member in database.')

    API.post('admins', '/create', {body: {...admins.new_admin_data}})
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', 'Record Created', 'Creating portal login for new staff member.')
        const password = generator.generate({
          length: 10,
          numbers: true,
          lowercase: true,
          uppercase: true,
          symbols: true,
          strict: true
        });

        create_staff_cognito_account(response.payload.insertId, admins.new_admin_data.email, password)
        openNotificationWithIcon('success', 'Record Created', 'New staff has been successfully created.')
        return response;
      })
      .then(response => {
        history.push('/admin/list');
      })
      .catch(error => {
        console.log(error);
      });
  }

    if (!admins && !admins.new_admin_data) {
      return null;
    }

    return (
      <React.Fragment>
        <Steps
          current={currentStep}
          status={stepStatusError ? 'error' : ''}
        >
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={classes.stepsContent}>
          {steps[currentStep].content}
        </div>
        <div className={classes.stepsAction}>
          {currentStep > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={prevStep}>
              Previous
            </Button>
          )}
          {currentStep < steps.length - 1 && (
            <Button type="primary" onClick={nextStep}>
              Next
            </Button>
          )}
          {currentStep === steps.length - 1 && (
            <Button type="primary" onClick={handleSubmit}>
              Done
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }


const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    admins: state.admins,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewStaffCreation: () => {
      dispatch(startNewStaffCreation())
    },
    updateNewStaffCreation: (key, value) => {
      dispatch(updateNewStaffCreation(key, value))
    }
  }
}

const AdminNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AdminNewPageComponent)));

export default AdminNewPageComponentContainer;
