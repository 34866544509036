import { combineReducers } from 'redux';

import app_state from './app_state';
import applicants from './applicants';
import language from './language';
import admins from './admins';
import accounts from './accounts';
import countries from './countries';
import backofficestaffs from './backofficestaffs';
import authReducer from './auth';
import advertisers from './advertisers';

const rootReducer = combineReducers({
  app_state,
  applicants,
  language,
  admins,
  accounts,
  auth: authReducer,
  backofficestaffs,
  countries,
  advertisers,
});

export default rootReducer;
