
import React, { Component } from 'react';
import { Card, PageHeader, Button } from 'antd';

import AdminListPageComponent from '../../../components/pages/dashboard/admin/AdminListPageComponent';

const StaffListPage = ({ history }) => {
  const onClickShowAll = () => {
    history.push('/admin/list/');
  }

  const onClickNewAdmin = () => {
    history.push('/admin/new');
  }

  return (
    <div style={{
      padding: 24,
      background: '#fff',
      minHeight: 360
    }}>
      <PageHeader
        style={{
          paddingLeft: 0,
          borderBottom: '1px solid rgb(235, 237, 240)',
          marginBottom: 16
        }}
        title="Admin Management"
      />
      <Card
        style={{ minHeight: 360 }}
        title="Current Admin List"
        extra={[
          <Button key="3" onClick={onClickNewAdmin}>Create New</Button>,
          <Button key="1" onClick={onClickShowAll} type="primary">
            Show All
            </Button>,
        ]}
      >
        <div style={{ paddingTop: 24 }}>
          <AdminListPageComponent />
        </div>
      </Card>
    </div>
  );
}

export default StaffListPage;
