// import Amplify from 'aws-amplify';
import {API, Auth} from 'aws-amplify';
// import config from '../config/aws_config';

export async function create_backoffice_staff_cognito_account(id, username, password) {
  try {
    const newUser = await Auth.signUp({
      username: username,
      password: password
    });

    console.log(newUser);

    await update_backoffice_staff_in_database(id, {
      aws_cognito_id: newUser.userSub
      // portal_account_created: "1"
    });

    await API.post('email', '/backofficestaff/new-backoffice-staff-created', {
      body: {
        id: id,
        username: username,
        password: password
        // language_id: language_id
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    await API.post('email', '/interaction', {
      body: {
        interaction: 'Create New Back Office Staff Member Record',
        data: {
          id: id,
          username: username,
          password: password,
          aws_cognito_id: newUser.userSub
        },
        result: 'New Back Office Staff record created successfully'
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    //// if (e.code === "UsernameExistsException") {
    ////   await Auth.resendSignUp(data.email);
    //// }
  }
}

async function update_backoffice_staff_in_database(backoffice_staff_id, backoffice_staff_record) {
  return API.put('backofficestaff', `/update/${backoffice_staff_id}`, {body: backoffice_staff_record});
}
