
import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const createLoggingAPI = (params) => {
  const path = '/backoffice/logging/create';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendRequest(params);
};

export {
  createLoggingAPI
};