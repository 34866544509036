import Amplify, {API, Auth} from 'aws-amplify';

import config from '../config/aws_config';

export async function create_staff_cognito_account(id, username, password) {
  try {
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_staff.REGION,
        userPoolId: config.cognito_staff.USER_POOL_ID,
        identityPoolId: config.cognito_staff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_staff.APP_CLIENT_ID
      }
    });

    const newUser = await Auth.signUp({
      username: username,
      password: password
    });

    console.log(newUser);

    await update_staff_in_database(id, {
      aws_cognito_id: newUser.userSub
      // portal_account_created: "1"
    });

    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_backofficestaff.REGION,
        userPoolId: config.cognito_backofficestaff.USER_POOL_ID,
        identityPoolId: config.cognito_backofficestaff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_backofficestaff.APP_CLIENT_ID
      }
    });

    await API.post('email', '/staff/new-staff-created', {
      body: {
        id: id,
        username: username,
        password: password
        // language_id: language_id
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
    await API.post('email', '/interaction', {
      body: {
        interaction: 'Create New Staff Member Record',
        data: {
          id: id,
          username: username,
          password: password,
          aws_cognito_id: newUser.userSub
        },
        result: 'Admin record created successfully'
      }
    })
      .then(response => {
        console.log(response);
      })
      .catch(error => {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    //// if (e.code === "UsernameExistsException") {
    ////   await Auth.resendSignUp(data.email);
    //// }
    Amplify.configure({
      Auth: {
        mandatorySignIn: true,
        region: config.cognito_backofficestaff.REGION,
        userPoolId: config.cognito_backofficestaff.USER_POOL_ID,
        identityPoolId: config.cognito_backofficestaff.IDENTITY_POOL_ID,
        userPoolWebClientId: config.cognito_backofficestaff.APP_CLIENT_ID
      }
    });
  }
}

async function update_staff_in_database(staff_id, staff_record) {
  return API.put('admins', `/update/${staff_id}`, {
    body: staff_record
  });
}
