export default {
  s3: {
    REGION: 'us-east-1',
    BUCKET: 'S3_BUCKET_ID'
  },
  apiGateway: {
    REGION: 'us-east-1'
  },
  cognito_backofficestaff: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_rwLZuBP9V',
    APP_CLIENT_ID: '3ue4sml30gj0g3or199sacpdqp',
    IDENTITY_POOL_ID: 'us-east-1:15c7ccb4-0f75-4e38-ab40-fa2bcf09eeb7'
  },
  cognito_admins: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_NkcBGmAG2',
    APP_CLIENT_ID: '6avnql4slvqdp27n671g47esbh',
    IDENTITY_POOL_ID: 'us-east-1:4c8037d5-18db-4967-9f25-fddbdad40ce1'
  },
  cognito_advertisers: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_hbd1XEphh',
    APP_CLIENT_ID: '2nm884mg20r7dpq44qfbia2hu1',
    IDENTITY_POOL_ID: 'us-east-1:a1a8fddf-c8b0-45d4-84b0-072013f6f356'
  },
  cognito_applicants: {
    REGION: 'us-east-1',
    USER_POOL_ID: 'us-east-1_qNmDotQ0t',
    APP_CLIENT_ID: '6i7312jfoetsq2ccmkum82ipg2',
    IDENTITY_POOL_ID: 'us-east-1:6fd778cd-e84b-4fe6-a787-65ea5df91ff0'
  }
};
