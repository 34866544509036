import DashboardPage from '../views/dashboard/DashboardPage';
import UserProfilePage from '../views/dashboard/UserProfilePage';
import AccountListPage from '../views/dashboard/accounts/AccountListPage';
import AccountNewPage from '../views/dashboard/accounts/AccountNewPage';
import AccountViewPage from '../views/dashboard/accounts/AccountViewPage';
import BackofficestaffNewPage from '../views/dashboard/backofficestaff/BackofficestaffNewPage';
import BackofficestaffListPage from '../views/dashboard/backofficestaff/BackofficestaffListPage';
import BackofficestaffViewPage from '../views/dashboard/backofficestaff/BackofficestaffViewPage';
import AdminListPage from '../views/dashboard/admin/AdminListPage';
import AdminNewPage from '../views/dashboard/admin/AdminNewPage';
import AdminViewPage from '../views/dashboard/admin/AdminViewPage';
import ApplicantNewPage from '../views/dashboard/applicants/ApplicantNewPage';
import ApplicantListPage from '../views/dashboard/applicants/ApplicantListPage';
import ApplicantViewPage from '../views/dashboard/applicants/ApplicantViewPage';
import AdvertiserNewPage from '../views/dashboard/advertisers/AdvertiserNewPage';
import AdvertiserListPage from '../views/dashboard/advertisers/AdvertiserListPage';
import AdvertiserViewPage from '../views/dashboard/advertisers/AdvertiserViewPage';
import JobListPage from '../views/dashboard/jobs/JobListPage';
import JobNewPage from '../views/dashboard/jobs/JobNewPage';
import JobViewPage from '../views/dashboard/jobs/JobViewPage';
import LegalPage from '../views/dashboard/LegalPage';
import SubscriptionNewPage from '../views/dashboard/subscriptions/SubscriptionNewPage';
import SubscriptionListPage from '../views/dashboard/subscriptions/SubscriptionListPage';
import SubscriptionViewPage from '../views/dashboard/subscriptions/SubscriptionViewPage';

const dashRoutes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage
  },
  {
    path: '/user-profile',
    name: 'User Profile',
    component: UserProfilePage
  },
  {
    path: '/backoffice-staff/new',
    name: 'Back Office Staff New',
    component: BackofficestaffNewPage
  },
  {
    path: '/backoffice-staff/list',
    name: 'Back Office Staff List',
     component: BackofficestaffListPage
  },
  {
    path: '/backoffice-staff/view/:backoffice_staff_id',
    name: 'Back Office Staff View',
    component: BackofficestaffViewPage
  },
  { 
    path: '/admin/new', 
    name: 'Admin New',
    component: AdminNewPage 
  }, 
  {
    path: '/admin/list', 
    name: 'Admin List',
    component: AdminListPage
  } , 
  {
    path: '/admin/view/:admin_id',
    name: 'Admin View',
    component: AdminViewPage
  },
  {
    path: '/advertisers/new',
    name: 'New Advertisers',
    component: AdvertiserNewPage
  },
  {
    path: '/advertisers/list',
    name: 'Advertiser List',
    component: AdvertiserListPage
  },
  {
    path: '/advertisers/view/:client_id',
    name: 'View Advertisers',
    component: AdvertiserViewPage
  },
  {
    path: '/applicants/new',
    name: 'New Applicants',
    component: ApplicantNewPage
  },
  {
    path: '/applicants/list',
    name: 'Applicant List',
    component: ApplicantListPage
  },
  {
    path: '/applicants/view/:client_id',
    name: 'View Applicants',
    component: ApplicantViewPage
  },
  {
    path: '/account/new',
    name: 'Account New',
    component: AccountNewPage
  },
  {
    path: '/account/list',
    name: 'Account List',
    component: AccountListPage
  },
  {
    path: '/account/view/:account_id',
    name: 'Account View',
    component: AccountViewPage
  },
  {
    path: '/jobs/new',
    name: 'Job New',
    component: JobNewPage
  },
  {
    path: '/jobs/list',
    name: 'Job List',
    component: JobListPage
  },
  {
    path: '/jobs/view/:job_id',
    name: 'Job View',
    component: JobViewPage
  },
  {
    path: '/legal',
    name: 'Legal',
    component: LegalPage
  },
  {
    path: '/subscriptions/new',
    name: 'Subscription New',
    component: SubscriptionNewPage
  },
  {
    path: '/subscriptions/list',
    name: 'Subscription List',
    component: SubscriptionListPage
  },
  {
    path: '/subscriptions/view/:subscription_id',
    name: 'Subscription View',
    component: SubscriptionViewPage
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/dashboard',
    name: 'Dashboard'
  }
];

export default dashRoutes;
