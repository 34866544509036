import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import NewAccountSinglePage from "./account_new/NewAccountSinglePage";
class AccountNewPageComponent extends Component {

  render() {
    if (!this.props.accounts && !this.props.accounts.new_account_data) return null;

    return (
      <React.Fragment>
        <NewAccountSinglePage />
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    accounts: state.accounts,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {}
}

const AccountNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountNewPageComponent));

export default AccountNewPageComponentContainer;
