import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getAccountAPI, updateAccountAPI } from '../../../../../services/accounts';
import { Row, Col, Button, notification, Form, Input } from 'antd';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class AccountSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  async componentDidMount() {
    const account = await getAccountAPI(
      this.props.account_id
    );
    console.log(account)
    this.setState({...account.payload})
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    // console.log(nextProps)
    // if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
    //   const user = await this.getUserProfile(
    //     nextProps.app_state.current_user_aws_cognito_id
    //   );
    //   // console.log(user)
    //   this.setState({
    //     ...user.payload
    //   })
    // }
  }

  async getAccountRecord(id) {
    return await getAccountAPI(id);
  }

  async saveAccountRecord(account_record) {
    await updateAccountAPI(this.state.id, account_record);
    openNotificationWithIcon('success', 'Save Successful', 'Your data has been saved successfully')
  }

  handleChange = event => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
  };

  handleSubmit = async event => {
    event.preventDefault();

    console.log(this.props);
    console.log(event)
    this.setState({ isLoading: true });

    const {
      // id,
      file_by,
      account_slug,
      account_name
    } = this.state;
    try {
      await this.saveAccountRecord({
        file_by,
        admin_backofficestaff_id: this.props.app_state.current_user_id,
        account_slug,
        account_name
      });
    } catch (e) {
      alert(e);
      try {
        await this.saveAccountRecord({
          // aws_cognito_id: aws_cognito_id,
          file_by,
          admin_backofficestaff_id: this.props.app_state.current_user_id,
          account_slug,
          account_name
        });
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', 'Save Failed', 'An error occurred in data saving')
        this.setState({ isLoading: false });
      }
    }

    await this.props.history.push('/account/list');
  };

  render() {
    console.log(this.state);
    // console.log(this.props);
    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item label="File by">
                    <Input
                      id="file_by"
                      value={this.state.file_by}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Account Slug">
                    <Input
                      id="account_slug"
                      value={this.state.account_slug}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item label="Account Admin">
                    <Input
                      id="admin_backofficestaff_id"
                      disabled
                      //value={this.props.app_state.current_user_id}
                      value={this.state.admin_firstname + ', ' + this.state.admin_lastname} //this.state.admin_user_id
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                  <Form.Item label="Account Name">
                    <Input
                      id="account_name"
                      value={this.state.account_name}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: 'right' }}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const AccountSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AccountSummary));

export default AccountSummaryContainer;
