
import React, { useEffect } from 'react';
import { Table, Tag } from 'antd';
import { NavLink } from 'react-router-dom';
import listHOC from '../../../common/listHOC';
import { getAdmins } from '../../../../redux/actions/admins';
import { connect } from 'react-redux';

const AdminListPageComponent = ({ getColumnSearchProps, getAdminsHandler, admins}) => {

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: 'descend',
      render: (text, record) => <React.Fragment>
        <NavLink to={`/staff/view/${record.id}`} >
          {text.toString().padStart(5, '0')}
        </NavLink>
      </React.Fragment>
    },
    {
      title: 'Status',
      dataIndex: 'staff_status',
      sorter: (a, b) => (a.staff_status ?? '').localeCompare(b.staff_status ?? ''),
      render: data_item => {
        switch (data_item) {
          case 1:
            return <Tag color={'purple'} style={{
              width: 100,
              textAlign: 'center'
            }} key={data_item}>DRAFT</Tag>
          case 2:
            return <Tag color={'blue'} style={{
              width: 100,
              textAlign: 'center'
            }} key={data_item}>PENDING</Tag>
          case 3:
            return <Tag color={'orange'} style={{
              width: 100,
              textAlign: 'center'
            }} key={data_item}>REGISTERED</Tag>
          case 4:
            return <Tag color={'green'} style={{
              width: 100,
              textAlign: 'center'
            }} key={data_item}>ACTIVE</Tag>
          case 5:
            return <Tag color={'red'} style={{
              width: 100,
              textAlign: 'center'
            }} key={data_item}>DELETED</Tag>
        }
      }
    },
    {
      title: 'Email',
      dataIndex: 'email',
      sorter: (a, b) => (a.email ?? '').localeCompare(b.email ?? ''),
      ...getColumnSearchProps('email'),
    },
    {
      title: 'First Name',
      dataIndex: 'first_name',
      sorter: (a, b) => (a.first_name ?? '').localeCompare(b.first_name ?? ''),
      ...getColumnSearchProps('first_name'),
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      sorter: (a, b) => (a.last_name ?? '').localeCompare(b.last_name ?? ''),
      ...getColumnSearchProps('last_name'),
    },
  ];

  useEffect(() => {
    getAdminsHandler();
  }, []);

  return (
    <React.Fragment>
      {/* STAFF LIST GOES HERE */}
      <Table
        columns={columns}
        dataSource={admins}
        pagination={{
          pageSizeOptions: ['10', '20', '30'], 
          showSizeChanger: true
        }}
      />
    </React.Fragment>
  );
}

const mapStateToProps = state => {
  return {
    admins: state.admins.admins,
  };
};

const mapDispatchToProps = ({
  getAdminsHandler: getAdmins,
})

export default connect(mapStateToProps, mapDispatchToProps)(listHOC(AdminListPageComponent));
