/* Definded the action type for applicants */
import applicantsType from '../type/applicants';

const initialState = {
/* Applicants List Store*/
  applicants: []
};

function applicants(state = initialState, action) {
  switch (action.type) {
  /* Applicant Reducer to save the applicants list */
    case applicantsType.GET_APPLICANTS:
      return {
        ...state,
        applicants: action.applicants
      };
    default:
      return state;
  }
}

export default applicants;