/* Definded the action type for advertisers */
import advertisersType from '../type/advertisers';

const initialState = {
/* Advertisers List Store*/
  advertisers: []
};

function advertisers(state = initialState, action) {
  switch (action.type) {
  /* Advertiser Reducer to save the advertisers list */
    case advertisersType.GET_ADERTISERS:
      return {
        ...state,
        advertisers: action.advertisers
      };
    default:
      return state;
  }
}

export default advertisers;
