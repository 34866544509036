import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import {
  startNewBackOfficeStaffCreation,
  updateNewBackOfficeStaffCreation
} from '../../../../redux/actions/backofficestaffs';

import { API } from 'aws-amplify';

import { Steps, Button, notification } from 'antd';

import NewStaffWizard01Container from './new_backofficestaff_wizard/NewStaffWizard01';
// import NewStaffWizard02Container from "./new_staff_wizard/NewStaffWizard02";
import NewStaffWizard03Container from './new_backofficestaff_wizard/NewStaffWizard03';

import { create_backoffice_staff_cognito_account } from '../../../../library/backofficestaff';
const generator = require('generate-password');

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

const { Step } = Steps;

const steps = [
  {
    title: 'Back Office Staff Details',
    content: <NewStaffWizard01Container />,
  },
  // {
  //   title: 'Contact Details',
  //   content: <NewStaffWizard02Container />,
  // },
  {
    title: 'Confirm',
    content: <NewStaffWizard03Container />,
  },
];

class BackOfficeStaffNewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      current_step: 0,
      step_status_error: false
    }
  }

  componentDidMount() {
    this.props.startNewBackOfficeStaffCreation();
  }

  validate_step_1 = async () => {
    let validate = true;
    this.props.updateNewBackOfficeStaffCreation('email_error_message', ' ');

    if (!(this.props.backofficestaffs.new_backoffice_staff_data.email.length > 2)) {
      this.props.updateNewBackOfficeStaffCreation('email_status', 'error');
      this.props.updateNewBackOfficeStaffCreation('email_error_message', 'Email is required');
      validate = false;
    } else {
      validate = await API.get('backofficestaff', `/check-email/${this.props.backofficestaffs.new_backoffice_staff_data.email}`)
        .then(
          database_result => {
            console.log(database_result);
            if (!database_result.payload) {
              this.props.updateNewBackOfficeStaffCreation('email_status', 'error');
              this.props.updateNewBackOfficeStaffCreation('email_error_message', 'Email is already in the database');
            }

            return database_result.payload;
          }
        )
    }

    if (!(this.props.backofficestaffs.new_backoffice_staff_data.first_name.length > 0)) {
      await this.props.updateNewBackOfficeStaffCreation('first_name_status', 'error');
      validate = false;
    }
    if (!(this.props.backofficestaffs.new_backoffice_staff_data.last_name.length > 0)) {
      await this.props.updateNewBackOfficeStaffCreation('last_name_status', 'error');
      validate = false;
    }

    //#12 adjust staff to handle accounts
    console.log(this.props.backofficestaffs.new_backoffice_staff_data.account_id)
    if (!this.props.backofficestaffs.new_backoffice_staff_data.account_id) {
      await this.props.updateNewBackOfficeStaffCreation('account_id_status', 'error');
      await this.props.updateNewBackOfficeStaffCreation('account_error_message', 'Account is required');
      validate = false;
    }

    console.log(validate);
    return validate;
  }

  async next_step() {
    let validate = true;
    switch (this.state.current_step) {
      case 0:
        validate = await this.validate_step_1();
        break;
      default:

    }

    //console.log(validate);
    if (!validate) {
      this.setState({step_status_error: true})
      return;
    }
    const current_step = this.state.current_step + 1;
    this.setState({ current_step });
  }

  prev_step() {
    const current_step = this.state.current_step - 1;
    this.setState({ current_step });
  }

  handleSubmit = async (event) => {
    console.log(this.props.backofficestaffs.new_backoffice_staff_data)
    openNotificationWithIcon('info', 'In Process', 'Creating new backoffice staff member in database.')

    API.post('backofficestaff', '/create', {body: {...this.props.backofficestaffs.new_backoffice_staff_data}})
      .then(response => {
        console.log(response)
        openNotificationWithIcon('success', 'Record Created', 'Creating portal login for new backoffice staff member.')
        const password = generator.generate({
          length: 10,
          numbers: true,
          lowercase: true,
          uppercase: true,
          symbols: true,
          strict: true
        });

        create_backoffice_staff_cognito_account(response.payload.insertId, this.props.backofficestaffs.new_backoffice_staff_data.email, password)
        openNotificationWithIcon('success', 'Record Created', 'New backoffice staff has been successfully created.')
        return response;
      })
      .then(response => {
        // console.log(database_result)
        // openNotificationWithIcon('success', "Record Created", "New client has been successfully created.")
        this.props.history.push('/backoffice-staff/list');
      })
      .catch(error => {
        console.log(error);
      });
  }

  render() {
    const { classes } = this.props;
    const { current_step } = this.state;
    // console.log(current_step);
    console.log(this.props);
    // console.log(this.state.step_status_error);
    if (!this.props.backofficestaffs && !this.props.backofficestaffs.new_backoffice_staff_data) {
      return null;
    }

    return (
      <React.Fragment>
        <Steps
          current={current_step}
          status={this.state.step_status_error ? 'error' : ''}
        >
          {steps.map(item => (
            <Step key={item.title} title={item.title} />
          ))}
        </Steps>
        <div className={classes.stepsContent}>
          {steps[current_step].content}
        </div>
        <div className={classes.stepsAction}>
          {current_step > 0 && (
            <Button style={{ marginLeft: 8 }} onClick={() => this.prev_step()}>
              Previous
            </Button>
          )}
          {current_step < steps.length - 1 && (
            <Button type="primary" onClick={() => this.next_step()}>
              Next
            </Button>
          )}
          {current_step === steps.length - 1 && (
            <Button type="primary" onClick={this.handleSubmit}>
              Done
            </Button>
          )}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    // staffs: state.staffs,
    backofficestaffs: state.backofficestaffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    startNewBackOfficeStaffCreation: () => {
      dispatch(startNewBackOfficeStaffCreation())
    },
    updateNewBackOfficeStaffCreation: (key, value) => {
      dispatch(updateNewBackOfficeStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const BackOfficeStaffNewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(BackOfficeStaffNewPageComponent)));

export default BackOfficeStaffNewPageComponentContainer;
