import React, {Component} from 'react';
import {Card, PageHeader, Button} from 'antd';

import SubscriptionNewPageComponent from '../../../components/pages/dashboard/subscriptions/SubscriptionNewPageComponent';

class SubscriptionNewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onClickShowAll = () => {
    this.props.history.push('/subscriptions/list/');
  }

  onClickNewSubscription = () => {
    this.props.history.push('/subscriptions/new');
  }

  render() {
    // console.log(this.state);
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Subscription Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Create a New Subscription Record"
          extra={[
            <Button key="3" onClick={this.onClickNewSubscription} type="primary">Create New</Button>,
            <Button key="1" onClick={this.onClickShowAll}>Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <SubscriptionNewPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default SubscriptionNewPage;
