import React from 'react';
import { Select, Typography } from 'antd';
import withStyles from 'react-jss';
import { connect } from 'react-redux';

const { Text } = Typography;
const styles = theme => ({
  form_label: { fontWeight: theme.label_font_weight },
  disabled_input: {
    color: theme.input_disabled_color,
    backgroundColor: theme.input_disbaled_background_color,
  },
});

const { Option } = Select;
function CountrySeleteComponent(props) {
  const { value, handleChange, countries, id } = props;
  const priorityCount = countries?.filter(item => item.priority).length;
  const priorityStyle = {
    borderBottom: '1px solid #000',
    margin: '0 10px',
    marginBottom: 20,
    borderBottom: '2px solid rgb(123 123 123)',
    paddingBottom: 10
  }
  const normalStyle = {
    margin: '0 10px',
  }

  const options = countries?.map((d, index) =>
    <Option
      key={`countries-${index}`}
      value={d.id}
      style={index === (priorityCount - 1) ? priorityStyle : normalStyle}
    >
      <Text >{d.full_name}</Text>
    </Option>);

  return (
    <Select
      id={id}
      showSearch
      style={{flex: 1}}
      value={value}
      placeholder="Select Country"
      showArrow={true}
      optionFilterProp="children"
      dropdownClassName="common-country-select-dropdown"
      filterOption={(input, option) => {
        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      }
      onChange={handleChange}
    >
      {options}
    </Select>
  )
};

const mapStateToProps = state => {
  return {
    countries: state.countries
  };
};

export default withStyles(styles, { injectTheme: true })(connect(mapStateToProps, null)(CountrySeleteComponent));