import { advertiserType } from '../type';
import {
  getAdertisersAPI,
  getAdvertiser,
  updateAdertiserAPI,
  createAdertiserAPI
} from '../../services/advertisers';

export const ADVERTISERS_START_NEW_ADVERTISER_CREATION = 'ADVERTISERS_START_NEW_ADVERTISER_CREATION';
export const ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION = 'ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION';

export function startNewClientCreation() {
  return {type: ADVERTISERS_START_NEW_ADVERTISER_CREATION};
}

export function updateNewClientCreation(key, value) {
  // console.log(key, value);
  return {
    type: ADVERTISERS_UPDATE_NEW_ADVERTISER_CREATION,
    value: {
      key,
      value
    }
  };
}

/* Define New action  */
const getAdvertisers = () => {
  return (dispatch) => {
    return getAdertisersAPI()
      .then((data) => {
        if (data) {
          return dispatch({
            type: advertiserType.GET_ADERTISERS,
            advertisers: data.data
          });
        } throw new Error(data.msg);
      })
      .catch(error => {})
    
  }
};

const getAdertiser = (id) => {
  return getAdvertiser(id)
    .then((data) => {
      if (data) {
        return data.data
      } throw new Error(data.msg);
    })
    .catch(error => {})
}

const updateAdertiser = (id, param) => {
  return (dispatch) => {
    return updateAdertiserAPI(id, param)
      .then((data) => {
        if (data) {
          return data.data
        } throw {}
      })
      .catch(error => {
      })
  }
}

const createAdertiser = (param) => {
  return (dispatch) => {
    return createAdertiserAPI(param)
      .then((data) => {
        if (data) {
          return data.data
        } throw {}
      })
      .catch(error => {
      })
  }
}

export {
  getAdvertisers,
  getAdertiser,
  updateAdertiser,
  createAdertiser
}