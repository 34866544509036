import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Row, Col, Select } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { getBackOfficeStaffAPI } from '../../../../../services/backofficestaff';
import { createAccountAPI } from '../../../../../services/accounts';

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class NewAccountSinglePage extends Component {
  async componentDidMount() {
    const { Option } = Select;

    var bo_staff_list_select_options = {};
    getBackOfficeStaffAPI().then(
      response => {
        bo_staff_list_select_options = response.data?.map(d => <Option key={d.id} >{`${d.first_name+", "+d.last_name} [${d.id}]`}</Option>);
        this.setState({
          bo_staff_list_select_options: bo_staff_list_select_options
        });
      }
    )
  }

  validate_step_1 = async () => {
    let validate = true;

    if (!(this.props.accounts.new_account_data.file_by.length > 2)) {
      validate = false;
    }

    if (!(this.props.accounts.new_account_data.admin_backofficestaff_id.length > 0)) {
      validate = false;
    }

    return validate;
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSelectChange_admin = (value) => {
    this.setState({
      "admin_backofficestaff_id": value
    });
  };

  handleSubmit = async (event) => {

    let validate = true;
    validate = await this.validate_step_1();

    if (validate) {
      await createAccountAPI({
        admin_backofficestaff_id: this.props.accounts.new_account_data.admin_backofficestaff_id,
        file_by: this.props.accounts.new_account_data.file_by
      }).then(database_result => {
          openNotificationWithIcon('success', "Record Created", "New account has been successfully created.")
          return database_result;
        })
        .then(database_result => {
          this.props.history.push(`/account/list`);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }

  render() {
    if (!this.props.accounts || !this.props.accounts.new_account_data) return null;

    return (

      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Row gutter={[12, 0]}>
                <Col xs={12}>
                  <Form.Item
                    label="File By (Nickname)"
                    style={{ marginBottom: 0 }}
                    validateStatus={this.props.accounts.new_account_data.file_by_status}
                    hasFeedback
                    help={this.props.accounts.new_account_data.file_by_status === "error" ? "File By is required" : ""}
                  >
                    <Input
                      id="file_by"
                      value={this.props.accounts.new_account_data.file_by}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col xs={12}>
                  <Form.Item
                    label="Admin User ID"
                    style={{ marginBottom: 0 }}
                    validateStatus={this.props.accounts.new_account_data.admin_backofficestaff_id_status}
                    hasFeedback
                    help={this.props.accounts.new_account_data.admin_backofficestaff_id_status === "error" ? "Admin User ID is required" : ""}
                  >
                    {/*<Input
                      id="admin_user_id"
                      value={this.props.accounts.new_account_data.admin_user_id}
                      onChange={event => {
                        this.handleChange(event);
                      }}
                    />*/}
                    <Select
                      id="admin_backofficestaff_id"
                      showSearch
                      value={
                        typeof this.state.admin_backofficestaff_id === "number"
                          ? this.state.admin_backofficestaff_id.toString()
                          : this.state.admin_backofficestaff_id
                      }
                      placeholder="Select Backoffice staff"
                      showArrow={true}
                      optionFilterProp="children"
                      filterOption={(input, option) => {
                        return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                      }
                      }
                      onChange={this.handleSelectChange_admin}
                    >
                      {this.state.bo_staff_list_select_options}

                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language,
    accounts: state.accounts
  };
};

const mapDispatchToProps = dispatch => {}

const NewAccountSinglePageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(NewAccountSinglePage));

export default NewAccountSinglePageContainer;
