import {
  LANGUAGE_SET_ID
} from '../actions/language.js';

const initialState = {
  language_current_ui: 'en',
  //is_fetching: false,
  //did_invalidate: false,
  language_list: ['en','zh'],
  // news_detail_fetching: false,
  // news_detail_loaded: false,
  // news_detail_id: null,
  // new_news_data: {},
  // new_news_type: null,
  // new_news_submitted: false,
  // new_news_created: false
};

function language(state = initialState, action) {
  switch (action.type) {

    case LANGUAGE_SET_ID:
      return {
        ...state,
        language_current_ui: action.value
      };

    default:
      return state;
  }
}

export default language;