
import React, { Component } from 'react';

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
import { Card, PageHeader, Button } from 'antd';

import ApplicantViewPageComponentContainer from '../../../components/pages/dashboard/applicants/ApplicantViewPageComponent';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ApplicantViewPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/applicants/list/');
  }

  handle_new_applicants = () => {
    this.props.history.push('/applicants/new');
  }

  render() {

    console.log(this.props.match.params.client_id)

    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Applicant Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="View and update your Applicant Details"
          extra={[
            <Button key="3" onClick={this.handle_new_applicants}>Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all} type="primary">
                    Show All
            </Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <ApplicantViewPageComponentContainer
              client_id={this.props.match.params.client_id}
            />

          </div>
        </Card>
      </div>

    );
  }
}

export default ApplicantViewPage;
