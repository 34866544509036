
import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  updateNewBackOfficeStaffCreation
} from '../../../../../redux/actions/backofficestaffs';
class NewStaffWizard03 extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  async componentDidMount() {

  }

  async componentWillReceiveProps(nextProps, nextContext) {

  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({[event.target.id]: event.target.value});
    this.props.updateNewBackOfficeStaffCreation(event.target.id, event.target.value);

  };

  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        {this.props.backofficestaffs.new_backoffice_staff_data.first_name}
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    backofficestaffs: state.backofficestaffs,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateNewBackOfficeStaffCreation: (key, value) => {
      dispatch(updateNewBackOfficeStaffCreation(key, value))
    }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const NewStaffWizard03Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewStaffWizard03);

export default NewStaffWizard03Container;
