import {
  BACKOFFICESTAFFS_START_NEW_STAFF_CREATION,
  BACKOFFICESTAFFS_UPDATE_NEW_STAFF_CREATION
} from '../actions/backofficestaffs';

const initialState = {
  new_backoffice_staff_data: null,
  new_backoffice_staff_submitted: false,
  new_backoffice_staff_created: false
};

function backofficestaffs(state = initialState, action) {
  switch (action.type) {
    case BACKOFFICESTAFFS_START_NEW_STAFF_CREATION:
      console.log('=debug=');
      return {
        ...state, // staff_detail: action.staff_detail,
        new_backoffice_staff_data: {
          first_name: '',
          last_name: '',
          email: '',
        },
        new_backoffice_staff_submitted: false,
        new_backoffice_staff_created: false
        // client_detail_loaded: true
        // staff_detail_loaded: true
      };
    case BACKOFFICESTAFFS_UPDATE_NEW_STAFF_CREATION:
      return {
        ...state,
        new_backoffice_staff_data: {
          ...state.new_backoffice_staff_data,
          [action.value.key]: action.value.value
        },
      };
    default:
      return state;
  }
}

// const rootReducer = combineReducers({
//   app_state
// });

export default backofficestaffs;
