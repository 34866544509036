
import React, { Component } from 'react';
import { connect } from "react-redux";

// import {
//   updateNewClientCreation

// } from "../../../../../redux/actions/clients";

// import logo from '../logo.svg';
//import '../App.css';
// import '../Components/DefaultPage.css';
import { API, Auth } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
import { Card, Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

import { Typography } from 'antd';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class StaffContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    // await API.get("countries", `list`).then(response => {
    //     console.log(response);
    //     console.log(response.payload);
    //   }
    // ).catch(error => {
    //   console.log(error);
    // });

    const staff = await this.getStaffRecord(
      this.props.staff_id
    );
    //console.log(staff)
    this.setState({
      ...staff.payload
    })
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      const staff = await this.getStaffRecord(
        this.props.staff_id
      );
      console.log(staff)
      this.setState({
        ...staff.payload
      })
    }
  }

  async getStaffRecord(staff_id) {
    return API.get("admins", `/get/id/${staff_id}`);
  }

  async saveStaffRecord(staff_record) {
    console.log(staff_record);
    await API.put("admins", `/update/${this.state.id}`, {
      body: staff_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }

  handleChange = (event) => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
    //this.props.updateNewClientCreation(event.target.id, event.target.value);

  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      // aws_cognito_id,
      // first_name,
      // middle_name,
      // last_name,
      // email,
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    } = this.state;
    try {
      await this.saveStaffRecord({
        // aws_cognito_id: aws_cognito_id,
        // first_name: first_name,
        // middle_name: middle_name,
        // last_name: last_name,
        tel_work,
        tel_home,
        tel_mobile,
        email_secondary
      });
      // this.props.history.push("/");
      // this.props.reload_user(this.state.aws_cognito_id);
    } catch (e) {
      // alert(e);
      try {
        await this.saveStaffRecord({
          // aws_cognito_id: aws_cognito_id,
          //first_name: first_name,
          // middle_name: middle_name,
          //last_name: last_name,
          tel_work,
          tel_home,
          tel_mobile,
          email_secondary
        });
        // this.props.history.push("/");
        // this.props.reload_user(this.state.aws_cognito_id);
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        // this.setState({ isLoading: false });
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    //console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical">
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Contact Details</Title>
              <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_home"
                  value={this.state.tel_home}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_work"
                  value={this.state.tel_work}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_mobile"
                  value={this.state.tel_mobile}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email"
                  value={this.state.email}
                  disabled
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email_secondary"
                  value={this.state.email_secondary}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

// export default UserSummary;

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    clients: state.clients,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // updateNewClientCreation: (key, value) => {
    //   dispatch(updateNewClientCreation(key, value))
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const StaffContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffContact);

export default StaffContactContainer;
