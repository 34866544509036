
import React, { Component } from 'react';
import { connect } from "react-redux";

import { API } from "aws-amplify";
import { Row, Col, Select, Skeleton } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';


const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class StaffSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaded: false
    }
  }


  async componentDidMount() {
    const staff = await this.getStaffRecord(
      this.props.staff_id
    );
    // console.log(staff)
    this.setState({
      ...staff.payload
    })

    const { Option } = Select;

    var account_list_select_options = {};
    API.get("accounts", "/list").then(
      response => {
        // console.log(response);
        account_list_select_options = response.payload.map(d => <Option key={d.id} >{d.file_by}</Option>);
        this.setState({
          account_list_select_options: account_list_select_options
        });
        this.setState({ loaded: true });
      }
    )
  }

  async componentWillReceiveProps(nextProps, nextContext) {
    //console.log(nextProps)
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      const staff = await this.getStaffRecord(
        nextProps.staff_id
      );
      // console.log(staff)
      this.setState({
        ...staff.payload
      })
    }
  }


  async getStaffRecord(user_id) {
    return API.get("admins", `/get/id/${user_id}`);
  }

  async saveStaffRecord(staff_record) {
    console.log(staff_record);
    await API.put("admins", `/update/${this.state.id}`, {
      body: staff_record
    });
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    // console.log(event)
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    // console.log(event)
    this.setState({ isLoading: true });

    const {
      first_name,
      middle_name,
      last_name,
      account_id
    } = this.state;
    try {
      await this.saveStaffRecord({
        first_name: first_name,
        middle_name: middle_name,
        last_name: last_name,
        file_by: `${last_name}, ${first_name}`,
        account_id: account_id
      });
    } catch (e) {
      // alert(e);
      try {
        await this.saveStaffRecord({
          first_name: first_name,
          middle_name: middle_name,
          last_name: last_name,
          file_by: `${last_name}, ${first_name}`,
          account_id: account_id
        });
      } catch (e) {
        // alert(e);
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
        this.setState({ isLoading: false });
      }
    }
  };

  handleSelectChange_account = (value) => {
    this.setState({
      "account_id": value
    });
  };

  render() {
    //console.log(this.state);

    return (

      <React.Fragment>
        <Skeleton
          active={!this.state.loaded}
          loading={!this.state.loaded}
        >
          <Form layout="vertical">

            <Row gutter={[96, 24]}>
              <Col xs={12}>
                <Row gutter={[12, 0]}>
                  <Col xs={12}>
                    <Form.Item label="First Name" style={{ marginBottom: 0 }}>
                      <Input
                        id="first_name"
                        value={this.state.first_name}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={12}>
                    <Form.Item label="Middle Name(s)" style={{ marginBottom: 0 }}>
                      <Input
                        id="middle_name"
                        value={this.state.middle_name}
                        onChange={event => {
                          this.handleChange(event);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item label="Last Name" >
                  <Input
                    id="last_name"
                    value={this.state.last_name}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={12}>
                <Form.Item
                  label="Account"
                  style={{ marginBottom: 0 }}
                >
                  <Select
                    id="account_id"
                    showSearch
                    value={
                      typeof this.state.account_id === "number"
                        ? this.state.account_id.toString()
                        : this.state.account_id
                    }
                    placeholder="Select Account"
                    showArrow={true}
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                      return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }}
                    onChange={this.handleSelectChange_account}
                  >
                    {this.state.account_list_select_options}

                  </Select>
                </Form.Item>
                <Form.Item label="E-mail">
                  <Input
                    id="email"
                    value={this.state.email}
                    disabled={true}
                    onChange={event => {
                      this.handleChange(event);
                    }}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[96, 24]} >
              <Col xs={24} style={{ textAlign: "right" }}>
                <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                  Save Record
              </Button>
              </Col>
            </Row>
          </Form>
        </Skeleton>
      </React.Fragment>
    );
  }
}


// export default UserSummary;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const StaffSummaryContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StaffSummary);

export default StaffSummaryContainer;
