import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Applicant Endpoint */
const getApplicantsAPI = () => {
  const path = '/backoffice/v1/applicants';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const getApplicant = (id) => {
  const path = `/backoffice/v1/applicants/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateApplicantAPI = (id, params) => {
  const path = `/backoffice/v1/applicants/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const createApplicantAPI = (params) => {
  const path = `/backoffice/v1/applicants/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

export {
  getApplicantsAPI,
  getApplicant,
  updateApplicantAPI,
  createApplicantAPI
};