
import React, { Component } from 'react';
import { Table , Input , Button } from 'antd';
import { NavLink } from 'react-router-dom';

import { API } from 'aws-amplify';

// import { Layout, Menu, Breadcrumb, Icon } from 'antd';
//import { Divider } from 'antd';

import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';

// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

// const expanded_row_layout = {
//   labelCol: { span: 8 },
//   wrapperCol: { span: 16 },
// };

class BackOfficeStaffListPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {dataSource: []}
    this.columns = [
      {
        title: 'ID',
        dataIndex: 'id',
        sorter: (a, b) => a.id - b.id,
        defaultSortOrder: 'descend',
        render: (text, record) => <React.Fragment>
          <NavLink to={`/backoffice-staff/view/${record.id}`} >
            {text.toString().padStart(5, '0')}
          </NavLink>
        </React.Fragment>
        //text => <a>{text.toString().padStart(5, '0')}</a>,
      },
      // {
      //   title: 'Status',
      //   dataIndex: 'staff_status',
      //   sorter: (a, b) => a.id - b.id,
      //   render: data_item => {
      //     switch (data_item) {
      //       case 1:
      //         return <Tag color={'purple'} style={{ width: 100, textAlign: "center" }} key={data_item}>DRAFT</Tag>
      //       case 2:
      //         return <Tag color={'blue'} style={{ width: 100, textAlign: "center" }} key={data_item}>PENDING</Tag>
      //       case 3:
      //         return <Tag color={'orange'} style={{ width: 100, textAlign: "center" }} key={data_item}>REGISTERED</Tag>
      //       case 4:
      //         return <Tag color={'green'} style={{ width: 100, textAlign: "center" }} key={data_item}>ACTIVE</Tag>
      //       case 5:
      //         return <Tag color={'red'} style={{ width: 100, textAlign: "center" }} key={data_item}>DELETED</Tag>
      //     }
      //   }
      // },
      {
        title: 'Email',
        dataIndex: 'email',
        ...this.getColumnSearchProps('email'),
      },
      {
        title: 'First Name',
        dataIndex: 'first_name',
        ...this.getColumnSearchProps('first_name'),
      },
      {
        title: 'Last Name',
        dataIndex: 'last_name',
        ...this.getColumnSearchProps('last_name'),
      },
      {
        title: 'Actions',
        key: 'action',
        render: (text, record) => (
          <span>
            <NavLink to={`/backoffice-staff/view/${record.id}`} >View</NavLink>
            {/*<Divider type="vertical" />
             <a>Delete</a>*/}
          </span>
        ),
      },
    ];
  }

  componentDidMount() {
    API.get('backofficestaff', '/list')
      .then(response => {
        console.log(response);

        const dataSource = [];
        for (let i = 0; i < response.payload.length; i++) {
          dataSource.push({
            key: response.payload[i].id,
            id: response.payload[i].id,
            email: response.payload[i].email,
            // tel_work: response.payload[i].tel_work,
            // tel_home: response.payload[i].tel_home,
            // tel_mobile: response.payload[i].tel_mobile,
            first_name: response.payload[i].first_name,
            last_name: response.payload[i].last_name,
            // staff_status: response.payload[i].staff_status
          });
        }
        console.log(dataSource);
        this.setState({dataSource: dataSource})
      })
      .catch(error => {
        console.log(error);
      });
  }

  // TABLE SEARCH
  // ========================================================================

  getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => {
            this.searchInput = node;
          }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            width: 188,
            marginBottom: 8,
            display: 'block'
          }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{
            width: 90,
            marginRight: 8
          }}
        >
          Search
        </Button>
        <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex] ?
        record[dataIndex]
          .toString()
          .toLowerCase()
          .includes(value.toLowerCase()) : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: text =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text?.toString()}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  // ========================================================================

  render() {
    // console.log(this.state);
    return (
      <React.Fragment>
        {/* STAFF LIST GOES HERE */}
        <Table
          columns={this.columns}
          dataSource={this.state.dataSource}
          // expandedRowRender={record => {
          //   console.log(record);
          //   return (<React.Fragment>
          //     <Row gutter={[96, 24]}>
          //       <Row>
          //       </Row>
          //       <Col xs={12}>
          //         <Form {...expanded_row_layout} onSubmit={this.handleSubmit}>
          //           <Form.Item label="Tel (home)" >
          //             <Input
          //               id="tel_home"
          //               value={record.tel_home}
          //               disabled={true}
          //             />
          //           </Form.Item>
          //           <Form.Item label="Tel (work)" >
          //             <Input
          //               id="tel_work"
          //               value={record.tel_work}
          //               disabled={true}
          //             />
          //           </Form.Item>
          //           <Form.Item label="Tel (mobile)" >
          //             <Input
          //               id="tel_mobile"
          //               value={record.tel_mobile}
          //               disabled={true}
          //             />
          //           </Form.Item>
          //         </Form>
          //       </Col>
          //     </Row>
          //   </React.Fragment>)
          // }}
        />
      </React.Fragment>
    );
  }
}

export default BackOfficeStaffListPageComponent;
