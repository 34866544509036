import React, { Component } from 'react';
import { connect } from "react-redux";

import { setLanguageSelectId } from "../../../redux/actions/language";
import { DashboardOutlined, UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

import { Tooltip } from 'antd';
import { PageHeader, Button } from 'antd';
import { Select } from 'antd';
import ReactCountryFlag from "react-country-flag"

const { Option } = Select;
class DashboardHeader extends Component {

  handle_language_select = (value) => {
    this.props.setLanguageSelectId(value);
  }

  handle_select_account_change = (value) => {
    console.log(value)
    // this.handle_switch_user(value)
  }

  handleLogout = async event => {
    console.log(event);
    this.props.handleLogout();
  }

  render() {
    // console.log(this.state);
    // console.log(this.props.app_state);

    const accounts_list_select_options = this.props.app_state.accounts.map(
      d => <Option key={d.id} >{d.file_by}</Option>
    );

    return (
      <React.Fragment>
        <PageHeader
          ghost={false}
          // onBack={() => window.history.back()}
          title="BackOffice Portal"
          // subTitle="This is a subtitle"
          extra={this.props.isAuthenticated ? [
            <Select
              defaultValue={this.props.language.language_current_ui}
              style={{ width: 180 }}
              onChange={this.handle_language_select}>
              <Option value="en">
                <ReactCountryFlag
                  className="emojiFlag"
                  countryCode="GB"
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.5em',
                  }}
                  aria-label="English Language"
                /> English
              </Option>
              <Option value="zh">
                <ReactCountryFlag
                  // className="emojiFlag"
                  countryCode="CN"
                  style={{
                    fontSize: '1.5em',
                    lineHeight: '1.5em',
                  }}
                  aria-label="Chinese Traditional Language"
                /> 繁體中文
              </Option>
            </Select>,
            <Tooltip title={<FormattedMessage id="tooltip.title_switch_account" />}>
              <Select
                id="client_id"
                // showSearch
                value={
                  typeof this.props.app_state.current_client_id === "number"
                    ? this.props.app_state.current_client_id.toString()
                    : this.props.app_state.current_client_id
                }
                // placeholder="Select Country"
                placeholder="Select Account"
                showArrow={true}
                optionFilterProp="children"
                // filterOption={(input, option) => {
                //   return option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                // }}
                onChange={this.handle_select_account_change}
              >
                {accounts_list_select_options}
              </Select>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_dashboard" />}>
              <Button
                key="1"
                type="primary"
                onClick={this.handleDashboard}
                icon={<DashboardOutlined />}
              >
              </Button>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_dashboard" />}>
              <Button
                key="1"
                type="primary"
                onClick={this.handleDashboard}
                icon={<DashboardOutlined />}
              >
              </Button>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_user_profile" />}>
              <Button
                key="2"
                type="primary"
                onClick={this.handleUser}
                icon={<UserOutlined />}
              >
              </Button>
            </Tooltip>,
            <Tooltip title={<FormattedMessage id="tooltip.title_logout" />}>
              <Button
                key="3"
                type="primary"
                onClick={this.handleLogout}
                icon={<LogoutOutlined />}
              >
              </Button>
            </Tooltip>] : []}
        >
        </PageHeader>
      </React.Fragment>
    );
  }
}

// export default DashboardHeader;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setLanguageSelectId: (language_id) => {
      dispatch(setLanguageSelectId(language_id))
    }
  }
}

const DashboardHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardHeader);

export default DashboardHeaderContainer;
