import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

/* Define the Accounts Endpoint */
const getListAPI = () => {
  const path = '/backoffice/v1/accounts';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const createAccountAPI = (params) => {
  const path = `/backoffice/v1/accounts/create`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.POST);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
};

const getAccountAPI = (id) => {
  const path = `/backoffice/v1/accounts/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);

  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const updateAccountAPI = (id, params) => {
  const path = `/backoffice/v1/accounts/${id}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.PUT);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER, params);
}

export {
  getListAPI,
  getAccountAPI,
  createAccountAPI,
  updateAccountAPI
};