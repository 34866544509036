import { Utils } from '../helpers';
import APIClient from '../helpers/api';
import APIConstants from '../helpers/api/constants';

const getAdminsAPI = () => {
  const path = '/backoffice/v1/admins';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const createAdminsAPI = (param) => {
  const path = '/backoffice/v1/admins';
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

const checkAdminEmailAPI = (email) => {
  const path = `/backoffice/v1/admins/check-email/${email}`;
  const url = `${Utils.serverUrl.API_BASE_URL}${path}`;
  const client = new APIClient(url, APIConstants.HTTPMethod.GET);
  return client.sendAuthenticatedRequest(APIConstants.AuthenticationType.BEARER);
};

export {
  getAdminsAPI,
  checkAdminEmailAPI
};