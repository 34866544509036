
import React, { Component } from 'react';

import { Card ,PageHeader, Button } from 'antd';
import ApplicantListPageComponent from '../../../components/pages/dashboard/applicants/ApplicantListPageComponent';
// const { Header, Content, Footer, Sider } = Layout;
// const { SubMenu } = Menu;

class ApplicantListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handle_show_all = () => {
    this.props.history.push('/applicants/list/');
  }

  handle_new_applicants = () => {
    this.props.history.push('/applicants/new');
  }

  render() {
    return (

      <div style={{
        padding: 24,
        background: '#fff',
        minHeight: 360
      }}>
        <PageHeader
          style={{
            paddingLeft: 0,
            borderBottom: '1px solid rgb(235, 237, 240)',
            marginBottom: 16
          }}
          title="Applicant Management"
        />
        <Card
          style={{ minHeight: 360 }}
          title="Current Applicant List"
          extra={[
            <Button key="3" onClick={this.handle_new_applicants}>Create New</Button>,
            <Button key="2" disabled>View Record</Button>,
            <Button key="1" onClick={this.handle_show_all} type="primary">Show All</Button>,
          ]}
        >
          <div style={{ paddingTop: 24 }}>
            <ApplicantListPageComponent />
          </div>
        </Card>
      </div>

    );
  }
}

export default ApplicantListPage;
