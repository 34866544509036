
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Row, Col } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';
import { getAccountAPI, updateAccountAPI } from '../../../../../services/accounts';
const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class AccountBranding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_saving: false
    }
  }


  async componentDidMount() {
    const account = await this.getAccountRecord(
      this.props.account_id
    );
    this.setState({
      ...account.data
    })
  }

  async getAccountRecord(id) {
    return await getAccountAPI(id);
  }

  async saveAccountRecord(account_record) {
    await updateAccountAPI(this.state.id, account_record);
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };


  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ is_saving: true });
    openNotificationWithIcon('info', "Save In Process", "Record is being saved")

    const {
      favicon,
    } = this.state;

    try {
      await this.saveAccountRecord({
        favicon,
      });
      this.setState({ is_saving: false });
      openNotificationWithIcon('success', "Save Complete", "Record has been successfully saved")
    } catch (e) {
      console.log(e);
      openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      this.setState({ is_saving: false });
    }
  };

  render() {
    // console.log(this.state);

    return (

      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              {/* <Title level={4}>Street Address</Title> */}
              <Form.Item
                label="favicon"
              >
                <Input
                  id="favicon"
                  value={this.state.favicon}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={12}>
            </Col>
          </Row>


          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.state.is_saving}
                onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // createNewStaffMember: (data) => {
    // dispatch(createNewStaffMember(data))
    // },
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const AccountBrandingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountBranding);

export default AccountBrandingContainer;
