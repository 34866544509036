
import React, { Component } from 'react';
import { connect } from "react-redux";

import { API } from "aws-amplify";

//import { Layout } from 'antd';
import { Row, Col } from 'antd';
// import { Card } from 'antd';
import { Button } from 'antd';
import { notification } from 'antd';
import { Form, Input } from 'antd';

import { Typography } from 'antd';

const { Title } = Typography;

const openNotificationWithIcon = (type, header, message) => {
  notification[type]({
    message: header,
    description: message,
  });
};

class AdvertiserContact extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }


  async componentDidMount() {
    const client = await this.getClientRecord(
      this.props.client_id
    );
    console.log(client)
    this.setState({
      ...client.payload
    })
}

  async componentWillReceiveProps(nextProps, nextContext) {
    console.log(nextProps)
    //NOTE: #34 to set this target client data back
    if (nextProps.app_state.current_user_id && nextProps.app_state.current_user_aws_cognito_id) {
      const client = await this.getClientRecord(
        //nextProps.app_state.current_user_aws_cognito_id
        nextProps.client_id
      );
    console.log(client)
      this.setState({
        ...client.payload
      })
    }
  }


  getClientRecord() {
    return this.props.advertisersList?.find(item => item.id == this.props.client_id);
  }

  async saveClientRecord(client_record) {
    await this.props.updateAdertiserHandler(this.state.id, client_record);
    openNotificationWithIcon('success', "Save Successful", "Your data has been saved successfully")
  }


  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();
    this.setState({ isLoading: true });

    const {
      tel_work,
      tel_home,
      tel_mobile,
      email_secondary
    } = this.state;
    try {
      await this.saveClientRecord({
        tel_work,
        tel_home,
        tel_mobile,
        email_secondary
      });
    } catch (e) {
      // alert(e);
      try {
        await this.saveClientRecord({
          tel_work,
          tel_home,
          tel_mobile,
          email_secondary
        });
      } catch (e) {
        openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      }

      // openNotificationWithIcon('error', "Save Failed", "An error occurred in data saving")
      // this.setState({ isLoading: false });
    }
  };

  render() {
    console.log(this.state);

    return (
      <React.Fragment>
        <Form layout="vertical" onSubmit={this.handleSubmit}>
          <Row gutter={[96, 24]}>
            <Col xs={12}>
              <Title level={4}>Contact Details</Title>
              <Form.Item label="Telephone (home)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_home"
                  value={this.state.tel_home}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (work)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_work"
                  value={this.state.tel_work}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Telephone (mobile)" style={{ marginBottom: 0 }}>
                <Input
                  id="tel_mobile"
                  value={this.state.tel_mobile}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Primary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email"
                  value={this.state.email}
                  disabled
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
              <Form.Item label="Secondary Email" style={{ marginBottom: 0 }}>
                <Input
                  id="email_secondary"
                  value={this.state.email_secondary}
                  onChange={event => {
                    this.handleChange(event);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[96, 24]} >
            <Col xs={24} style={{ textAlign: "right" }}>
              <Button type="primary" htmlType="submit" onClick={this.handleSubmit}>
                Save Record
              </Button>
            </Col>
          </Row>
        </Form>
      </React.Fragment>
      
    );
  }
}


// export default UserSummary;



const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    language: state.language,
    advertisersList: state.advertisers.advertisers, // Advertisers Action Store
  };
};

const mapDispatchToProps = dispatch => {
  return {
  }
}

const AdvertiserContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AdvertiserContact);

export default AdvertiserContactContainer;
