import React, { Component } from 'react';
import withStyles from 'react-jss'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs } from 'antd';

import StaffSummary from './admin_view/StaffSummary';
import StaffContact from './admin_view/StaffContact';
import StaffAddress from './admin_view/StaffAddress';

const styles = {
  stepsContent: {
    minHeight: 280,
    paddingTop: 48,
  },
  stepsAction: {
    marginTop: 24,
    float: 'right'
  },
};
const { TabPane } = Tabs;
function callback(key) {
  //console.log(key);
}

class AdminViewPageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {current_step: 0,}
  }

  componentDidMount() {

  }

  render() {
    // const { classes } = this.props;
    // const { current_step } = this.state;

    console.log(this.props);
    // if (!this.props.admins && !this.props.staffs.new_admin_data) {
    //   return null;
    // }

    return (

      <React.Fragment>

        <Tabs defaultActiveKey="1" onChange={callback}>
          <TabPane tab="Admin Summary" key="1">

            <StaffSummary
              staff_id={this.props.staff_id}
            />

          </TabPane>
          <TabPane tab="Contact" key="2">
            <StaffContact
              staff_id={this.props.staff_id}
            />
          </TabPane>
          <TabPane tab="Address" key="3">
            <StaffAddress
              staff_id={this.props.staff_id}
            />
          </TabPane>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    app_state: state.app_state,
    admins: state.admins,
    language: state.language
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // startNewClientCreation: () => {
    //   dispatch(startNewClientCreation())
    // }
    // cancelCreateNewStaffMember: () => {
    // dispatch(cancelCreateNewStaffMember())
    // },
    // confirmNewStaffMember: (data) => {
    //   dispatch(confirmNewStaffMember(data))
    // },
    // selectStaffUi: (data) => {
    //   dispatch(selectStaffUi(data))
    // },
  }
}

const AdminViewPageComponentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withStyles(styles)(AdminViewPageComponent)));

export default AdminViewPageComponentContainer;
