import PagesContainer from '../layouts/Pages.jsx';
import DashboardContainer from '../layouts/Dashboard.jsx';
import ConfirmEmailAddress from '../views/pages/ConfrimEmailAddressPage';
import ResetPassword from '../views/pages/ResetPassword';


var authRoutes = [
  {
    path: '/',
    name: 'Home',
    component: DashboardContainer
    //component: Dashboard
  }
];

var unAuthRoutes = [
  {
    path: '/pages',
    name: 'Pages',
    component: PagesContainer
    //component: PagesContainer
  },
];

var appliedRoutes = [
  {
    path: '/confirm-email',
    name: 'ConfirmEmailAddress Page',
    component: ConfirmEmailAddress
  },
  {
    path: '/reset-password',
    name: 'Reset Password Page',
    component: ResetPassword
  },
]
export {
  authRoutes,
  unAuthRoutes,
  appliedRoutes
};
